body, html {
    font-family: Segoe UI,-apple-system,BlinkMacSystemFont,Roboto,Helvetica Neue,Helvetica,Ubuntu,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol;
}

a {
    color: #1a0dab !important;
}

.blue a {
    color: #185795 !important;
}

img {
    max-width: 100%;
    height: auto;
}

strong {
    font-weight: bold !important;
}

p {
    margin-bottom: 30px;
}

i {
    font-style: normal !important;
}

h2 {
    font-size: 3.56rem;
    line-height: 3.916rem;
    margin: 1.78rem 0 1.424rem
}

h3 {
    font-size: 1.92rem;
    line-height: 3.212rem;
    margin: 1.46rem 0 1.168rem;
}

h4 {
    font-size: 1.28rem;
    line-height: 2.508rem;
    margin: 1.14rem 0 0.912rem 0;
}

ul.check li {
    background: url("./../../img/icons/check.png") no-repeat top 5px left;
    padding-left: 40px;
    margin-bottom: 20px;
}

ul.check li:last-child {
    margin-bottom: 0;
}


.text-center {
    text-align: center
}

.continue-link {
    font-size: 11.5px;
    font-weight: 400;
    text-transform: capitalize;
}

.main-menu li a {
    color: #009eda;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: .3s;
    -moz-transition: .3s;
    -o-transition: .3s;
    -ms-transition: .3s;
    transition: .3s;
}

.vue-components-container {
    width: 100%;
    display: flex;
}

.new-seo-page h1 {
    font-size: 2em;
}

.new-seo-page h2 {
    font-size: 1.4em;
    line-height: 2rem;
}

input[type="text"], input[type="password"], input[type="email"], input[type="date"], input[type="number"], textarea, .select-wrapper input.select-dropdown {
    background: #fff;
    border: 0;
    border-radius: 30px;
    height: 42px;
}

.btn-submit {
    background-color: #a4c843;
    background-size: 32px 32px;
    border-radius: 10px;
    color: #fff;
    height: 62px;
    margin-bottom: 0;
    width: 100%
}

.btn-middle {
    height: 39px;
}

.btn-submit:focus,
.btn-submit:hover {
    background: #77932b none;
    box-shadow: none;
}

#input-newsletter {
    border: 2px solid #a4c843;
    border-radius: 30px;
    height: 55px;
    margin-right: 15px;
    padding-left: 10px;
    box-shadow: none;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}


/* Top header */
.top-header {
    background: #bee1fe;
    height: 70px;
}

.top-header .logo {
    margin-top: 14.5px;
}

.top-menu {
    margin: 0;
    height: 70px;
    display: table;
}

.top-menu li {
    display: table-cell;
    vertical-align: middle;
    padding: 0 10px;
    position: relative;
}

.top-menu li .language-switcher li span {
    width: 20px;
    float: left;
    display: inline-block;
    height: 12px;
    position: relative;
    top: 6px;
    margin-right: 5px;
}

.top-menu li .sub-menu {
    opacity: 0;
    visibility: hidden;
    background: white;
    z-index: 1;
    top: 60px;
    right: 0px;
    bottom: auto;
    left: auto;
    position: absolute;
    min-width: 77px;
    padding: 0 15px;
    border-bottom: 5px solid #009eda;
    -webkit-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
    -moz-box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.21);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
}

.cms-call-to.rounded {
    background-color: #a4c843;
}

.top-menu li .sub-menu.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0px);
    -webkit-transform: translateY(0px);
}

.top-menu li .sub-menu.login-container {
    width: 300px;
    padding-bottom: 15px;
}

.top-menu li .sub-menu.login-container input {
    background: #eee;
    padding: 0 5%;
    width: 90%;
    margin-bottom: 0;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.top-menu li .sub-menu.login-container a {
    margin: 10px 10px 0;
    display: inline-block;
}

.top-menu li .sub-menu.login-container .social-button {
    margin-top: 80px;
}

.top-menu li .sub-menu.login-container button.btn {
    background: #009eda 0;
    text-decoration: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    color: #FFF;
    text-align: center;
    letter-spacing: .5px;
    cursor: pointer;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    width: 150px;
    height: 45px;
    line-height: 45px;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.top-menu li .sub-menu.login-container button.btn:hover {
    background: #0080b1 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.top-menu li .sub-menu.login-container button.btn:active {
    background: #00678d 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.top-menu li .sub-menu.login-container #sociallogin_button ul li.button, 
.top-menu li .sub-menu.login-container .sociallogin-other ul li {
    width: 100%;
    padding: 0 15px;
}

.top-menu li .sub-menu.login-container button.bt-login-social, 
.top-menu li .sub-menu.login-container #sociallogin_button button > span {
    width: 100%;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    height: 45px !important;
    line-height: 45px;
}

.top-menu li .sub-menu.login-container button.bt-login-social, 
.top-menu li .sub-menu.login-container #sociallogin_button button > span > span {
    width: 94%;
    height: 45px !important;
    line-height: 45px;
    margin-left: 30px;
    font-size: 14px;
    color: white;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
}

.top-menu li .sub-menu.login-container #sociallogin_button #bt-loginfb > span, 
.top-menu li .sub-menu.login-container #bt-loginfb-popup > span {
    background: url("./../../img/fb.png")
}

.top-menu li .sub-menu.login-container #sociallogin_button #bt-logingo > span, 
.top-menu li .sub-menu.login-container #bt-logingo-popup > span {
    background: url("./../../img/go.png") no-repeat !important;
}

.top-menu li .sub-menu.login-container #sociallogin_button #bt-loginya > span, 
.top-menu li .sub-menu.login-container #bt-loginya-popup > span {
    background: url("./../../img/ya.png") no-repeat !important;
}

.top-menu li .sub-menu li {
    display: block;
    padding: 5px 0;
}

.top-menu li .sub-menu li a {
    font-size: 14px;
    color: black;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
    font-weight: 600;
}

.top-menu li .sub-menu li a:hover {
    color: #009eda;
}

.top-menu li a, 
.top-menu li span {
    color: black;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}

.top-menu li a:hover, 
.top-menu li span:hover {
    color: #009eda;
}

.top-menu li .login {
    background: #009eda;
    color: white;
    padding: 8px 25px;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.top-menu li .login:hover {
    color: white;
    cursor: pointer;
}

.top-menu li .signup {
    background: white;
    color: #009eda;
    padding: 8px 25px;
    -webkit-box-shadow: inset 0 0 0 1px #cfcfcf;
    -moz-box-shadow: inset 0 0 0 1px #cfcfcf;
    box-shadow: inset 0 0 0 1px #cfcfcf;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
}

.top-menu li .signup:hover {
    background: #009eda;
    color: white;
}

.top-menu li .signup.active {
    background: #a4c843;
    color: white;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.top-menu li .signup.active:hover {
    background: #87a731;
}

.top-menu li.has-sub a span {
    float: left;
    position: relative;
    top: 12px;
    margin-right: 5px;
}

.top-menu li.has-sub a img {
    position: relative;
    top: 4px;
}

.top-menu li.has-sub:hover {
    cursor: pointer;
}

.top-menu .go-log-img {
    width: 35px;
    height: 35px;
    margin-top: 15px;
}


/* Header */
.header {
    height: 60px;
}

.header.fixed {
    background: #fff;
    -webkit-box-shadow: 0 1px 2px rgba(51,51,51,.17);
    -moz-box-shadow: 0 1px 2px rgba(51,51,51,.17);
    box-shadow: 0 1px 2px rgba(51,51,51,.17);
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 2
}

.header.fixed .logo {
    margin-top: 10px;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -webkit-transition: .4s;
    -moz-transition: .4s;
    -o-transition: .4s;
    -ms-transition: .4s;
    transition: .4s
}

.hidden-logo {
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
    -webkit-transform: translateY(-20px);
    display: none;
}

.main-menu {
    margin: 0;
    height: 60px;
    display: table;
}

.main-menu li {
    display: table-cell;
    vertical-align: middle;
    padding: 0 20px;
    position: relative;
}

.main-menu li a {
    color: #009eda;
    font-weight: 700;
    text-transform: uppercase;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}

/* Operation countries */
.operation-countries {
    padding: 50px 0;
}

.operation-countries .row {
    margin-bottom: 70px;
}

.operation-countries a {
    font-size: 16px;
    font-weight: 600;
    display: block;
    padding-bottom: 24px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}


/* Footer */
.footer {
    padding: 50px 0;
}

.footer .row {
    margin-bottom: 0;
}

.footer-menu {
    margin: 0;
}

.footer-menu li {
    display: block;
}

.footer-menu li a {
    font-size: 14px;
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}

.social-icons {
    margin: 0;
}

.social-icons li {
    display: inline-block;
    margin: 9px 2px 0;
}

/* Copyright */
.copyright {
    padding: 20px 0;
}

.copyright .row {
    margin-bottom: 0;
}

.copyright span {
    font-size: 14px;
    font-weight: 600;
    padding-top: 6px;
    display: inline-block;
}

.btn, 
.btn-large, 
.btn:hover, 
.btn-large:hover {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.m0, .card {
    margin: 0;
}

/* Loading */
.load-more {
    overflow: hidden;
    -webkit-transition: all .2s ease, background-color .01s ease, color .01s ease;
    transition: all .2s ease, background-color .01s ease, color .01s ease;
}

/* Custom placeholder color */
::-webkit-input-placeholder {
    color: #999;
}

#slide-out {
    left: inherit !important;
    right: -250px;
}

.new-seo-page .number-prefix .number-check-prefix {
    border: 1px solid #ccc;
    text-align: center;
    border-radius: 0;
    height: 55px;
}

.new-seo-page .lp-links {
    margin: auto;
}

.new-seo-page .cms-social-facebook {
    background-color: #2d78bd;
}

.new-seo-page .cms-social-facebook-text {
    color: white;
    font-size: 20px;
    text-align: center;
}


.step-one-col {
    text-align: center;
    position: relative;
}

.cms-page-view .step-one-col {
    width: 100% !important;
}

.btn-large-banking {
    width: 50%;
    padding: inherit !important;
    height: 55px;
}

.validation-advice {
    background-color: #fff6f6;
    border: 1px solid #911;
    border-radius: 5px;
    clear: both;
    color: #911 !important;
    float: none;
    font-weight: 400;
    margin: 10px 0;
    padding: 10px 15px 10px 15px;
    display: block;
}


.success-msg {
    background-color: #fff;
    border: 1px solid #7aaa65;
    color: #7aaa65 !important;
    padding: 10px 15px 10px 15px;
}

/*------------------------------------------------------------- CART ---------------*/
.mobile-cart {
    text-align: center;
    margin-left: 0;
    padding: 10px;
    border-top: 1px solid #ccc;
    background-color: #ececec;
    border-bottom: 1px solid #ccc;
}

.mobile-cart a {
    color: #009eda;
    font-weight: bold;
}

.mobile-cart a img {
    margin-right: 10px;
    /* height: 16px; */
    width: 35px;
    margin-bottom: -7px;
}

.main-menu .cart a {
    color: white;
}

.main-menu li.cart {
    background-color: #009eda;
    color: white;
    text-align: center;
    cursor: pointer;
}

.main-menu li.cart:hover {
    color: #fff;
    background-color: #0a89b9;
}

.main-menu li.cart:hover a {
    color: #fff;
}

.main-menu .cart a span.badge {
    position: inherit;
    background-color: #ffffff;
    color: #009eda;
    border-radius: 15px;
    margin-left: 10px;
    padding: 2px 5px;
}

.main-menu .cart a span.badge:hover {
    position: inherit;
    background-color: #009eda;
    color: white;
    border-radius: 15px;
    margin-left: 10px;
    padding: 2px 5px;
}

.mobile-cart a span.badge {
    position: inherit;
    background-color: #009eda;
    color: white;
    padding: 5px 10px;
    margin-left: 5px;
    border-radius: 15px;
}

.main-menu .cart .cart-left {
    float: left;
    margin-right: 10px;
    margin-top: 5px;
}

.main-menu .cart .cart-right {
    float: left;
}

/*------------------------------------------------------------- END CART ---------------*/


.show {
    display: block;
}


i[data-formatamounttolocale] {
    font-style: normal
}

.footer-powered-by {
    text-align: center
}

input.no-spin-number-input::-webkit-outer-spin-button, 
input.no-spin-number-input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0 !important
}

input.no-spin-number-input[type=number] {
    -moz-appearance: textfield !important
}

.cms-page-view {
    div: #adadad vice-required-entry-email;
    width: 100%
}

/* Mobile Language Selector */
#header_mobile_language_selector, #hmls_actual {
    display: none;
}

.new-seo-page {
    background-color: #eee;
}

.new-seo-page .container {
    background-color: #eeeeee;
    margin: auto;
}

.internal_links_columns {
    float: left;
    width: 33%;
    overflow: hidden;
}
input.no-spin-number-input:focus {
    border: none !important;
    box-shadow: none !important;
}


.center-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.rounded {
    border-radius: 20px;
}


/* Smartphones (portrait and landscape) ----------- */
@media screen and (max-width: 640px) {
    #header_mobile_language_selector {
        display: block;
        position: absolute;
        right: 70px;
        padding-top: 8px;
    }

    select#hmls_actual {
        display: block !important;
    }

    select#hmls_actual {
        text-transform: uppercase;
    }

    .side-nav {
        background: #FFFFFF;
    }

    .country-slider, 
    .top-menu, 
    .main-menu, 
    .top-header, 
    .slick-prev, 
    .slick-next, 
    .how-works 
    .step-list 
    .circle-left, 
    .how-works 
    .step-list 
    .circle-right {
        display: none;
    }

    .hidden-logo {
        display: block;
        opacity: 1;
        visibility: visible;
        transform: none;
        margin-top: 12px;
    }

    .side-nav {
        background: #FFFFFF;
    }

    .side-nav a {
        color: #185795 !important;
        text-transform: uppercase;
        font-weight: 700;
    }

    .side-nav li {
        border-bottom: 1px solid #f1f1f1;
    }

    .side-nav li:hover, .side-nav li.active {
        background: #185795;
    }

    .side-nav li:hover a, .side-nav li.active a{
        color: white !important;
    }
    .side-nav .collapsible-body {
        background: #0079a7;
    }

    .mobile-icon {
        background: url("./../../img/icons/mobile-nav-menu-icon.png");
        width: 25px;
        height: 20px;
        border: none;
        margin-top: 20px;
    }

    .footer-menu {
        text-align: center;
        margin-bottom: 20px;
    }

    .social-icons {
        text-align: center;
    }

    .copyright {
        text-align: center;
    }

    .center-align-mobile {
        text-align: center !important;
    }

    .internal_links_columns {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
    }

    .new-seo-page h1 {
        padding-top: 5%;
    }

    .new-seo-page .valign-wrapper {
        display: block !important;
    }

    .new-seo-page .rounded,
    .internal-links-module {
        width: 100%;
        background-color: white;
        margin-bottom: 10px;
        padding: 20px;
        border-radius: 0px;
        text-align: left;
        float: left;
        word-break: normal;
        max-width: 467px;
    }

    .lp-links .sections {
        text-align: left;
        float: left;
        width: auto;
        margin-left: 10px;
    }

    .new-seo-page .number-prefix .number-check-prefix {
        width: 25%;
        height: 55px;
    }

    .new-seo-page .number-prefix .number-field {
        width: 75%;
        height: 55px;
        border: 1px solid #ccc;
    }

    .new-seo-page .number-check-btn {
        width: 100%;
        height: 55px;
        border: 1px solid #ccc;
        text-align: center;
        background-color: #009eda;
        color: white;
    }

    .ds-banner div a img {
        height: 76px;
        width: 370px;
    }

    .new-seo-page .rounded.cms-call-to {
        background-color: #a4c843;
    }


    .new-seo-page .cms-social-facebook {
        background-color: #2d78bd;
    }
}

@media screen and (min-width: 640px) {


    .new-seo-page .rounded.cms-call-to {
        background-color: #a4c843;
    }

    .step-one-col {
        float: right !important;
        margin-right: 20px;
    }

    .new-seo-page .rounded, 
    .new-seo-page .full-row {
        margin: auto;
        margin-bottom: 1%;
        margin-top: 1%;
    }

    .new-seo-page .rounded,
    .internal-links-module {
        width: 100%;
        margin: 10px;
        padding: 2em;
        margin-left: 1%;
        background-color: white;
        border-radius: 15px;
        float: left;
        text-align: justify;
    }

    .new-seo-page .rounded:first-child {
        /* margin-left: 0; */
    }

    .new-seo-page .number-field {
        float: left;
        width: 100%;
        border: 1px solid #ccc;
        height: 55px;
    }

    .new-seo-page .number-check-btn {
        margin-bottom: 0px;
        background-color: #009eda;
        border: 0px;
        color: white;
        height: 55px;
    }


    .hidden-logo {
        opacity: 1;
        visibility: visible;
        transform: none;
        margin-top: 12px;
    }

    .side-nav {
        background: #009eda;
    }

    .side-nav a {
        color: #185795 !important;
        text-transform: uppercase;
        font-weight: 700;
    }

    .side-nav li:hover, .side-nav li.active {
        background: #00729d;
    }

    .side-nav .collapsible-body {
        background: #0079a7;
    }

    .intro .selection {
        width: 100%;
    }

    .intro h1 {
        margin: 0;
        font-size: 40px;
        line-height: 40px;
        overflow-wrap: break-word;
    }

    .intro .selection {
        width: 100%;
        margin-top: 15px;
        padding: 30px 0px;
    }

    .ds-banner div a img {
        height: 181px;
        width: 885px;
    }

    .how-it-works .item, .wrapper .intro .item {
        margin-bottom: 40px;
    }

    .how-it-works .item .circle-left, 
    .how-it-works .item .circle-right, 
    .wrapper .intro .item .circle-left, 
    .wrapper .intro .item .circle-right {
        display: none;
    }

    .how-it-works h2 {
        margin-bottom: 30px;
        font-size: 24px;
    }

    .how-it-works a {
        width: 100%;
        margin-top: 40px;
    }

    .how-it-works p {
        margin-top: 10px;
        font-size: 18px;
    }

    .how-it-works .icon-box {
        width: 100px;
        height: 100px;
    }

    .how-it-works .icon-box .icon {
        font-size: 30px;
    }

    .why-choose .col {
        margin-bottom: 40px;
    }

    .why-choose h2 {
        margin-bottom: 20px;
    }

    .why-choose img {
        margin: 10px 0;
        max-width: 100px;
    }

    .operation-countries p.note {
        padding: 0;
    }

    .footer-menu {
        text-align: center;
        margin-bottom: 20px;
    }

    .social-icons {
        text-align: center;
    }

    .copyright {
        text-align: center;
    }

    .copyright img {
        margin-top: 20px;
    }

    .online-banking-title {
        line-height: 30px;
        text-align: center;
    }

    .wrapper .intro .receiver-info {
        margin-top: 30px;
    }

    .wrapper .intro .receiver-info .receiver-fields {
        padding: 0;
    }

    .wrapper .intro .fields .select-wrapper {
        margin: 0;
    }

    .wrapper .intro .fields input[type="submit"] {
        margin: 0 auto 20px;
    }

    .wrapper .intro .payment-methods {
        padding: 0;
    }

    .choosing ul li {
        margin: 10px 0;
        width: 85px;
        float: none !important;
    }

    .center-align-mobile {
        text-align: center !important;
    }

    .registration .social-login .facebook-login, .registration .social-login .twitter-login {
        width: 100%;
        display: block;
        float: none;
        margin: 0 0 10px;
    }

    .registration-form .radio-buttons {
        padding: 0 20px;
        margin-bottom: 0;
    }

    .registration-form .radio-buttons label {
        margin-right: 10px;
    }

    .registration-form .field {
        margin-bottom: 20px;
    }

    .registration-form .field:last-child {
        margin-bottom: 0;
    }

    .registration-form input[type="email"], .registration-form input[type="text"], .registration-form input[type="password"], .registration-form input[type="email"] {
        margin: 0;
    }

    .success-info, .unsuccess-info {
        text-align: center;
    }

    .success-info img, .unsuccess-info img {
        float: none;
        margin: 0 0 10px;
    }

    .success-info .invalid, .unsuccess-info .invalid {
        margin: 0 0 10px;
    }

    .trustpilot {
        margin-bottom: 20px;
    }

    .call-to-action a {
        margin-top: 20px;
    }

    .meter-container {
        margin-top: 0px;
    }

    .meter-container .meter.large {
        width: 100%;
        margin-bottom: 40px;
    }

    .meter-container .meter.large .eur-left {
        top: 15px;
        left: 5px;
    }

    .meter-container .meter.large .eur-right {
        right: 5px;
        top: 15px;
    }

    .list {
        margin-bottom: 40px;
    }

    .steps {
        padding: 0;
        margin: 0 auto 20px;
    }

    .steps li:not(.step-divider) {
        width: 110px;
        height: 110px;
        display: inline-block;
        margin: 0 12px;
        line-height: 110px;
    }

    .loading-container {
        float: none;
    }

    .loader {
        margin: 0 auto 20px;
    }

    .loader-text {
        line-height: initial;
    }

    .file-field {
        position: relative;
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
    }

    .kyc-upload .file-meta {
        margin-top: 55px;
    }

    .kyc-upload .file-meta p.filename {
        word-break: break-all;
    }

    .country-slider {
        height: 220px;
    }

    .choosen-flag {
        text-align: center;
    }

    .verification-image {
        padding: 30px 0 20px !important;
        text-align: center;
    }

    .landing-text .sending .item {
        text-align: center;
    }

    .landing-text .sending .item img {
        float: none !important;
        margin-bottom: 10px;
    }

    .landing-text .sending .item h3 {
        float: none !important;
        text-align: center;
        line-height: 36px !important;
    }

    .our-video .video-container iframe, .landing-online-banking .table, .landing-online-topup .table {
        width: 100%;
    }

    .cubacel-title, .how-it-works-title {
        line-height: 30px;
    }

    .promotions span {
        word-wrap: break-word;
    }

    .how-works h3 {
        line-height: 28px;
        margin: 40px 0 10px;
    }

    .team .member, .advisors .advisor {
        text-align: center;
        margin-bottom: 0px;
    }

    .team .member .member-image, .team .member .advisor-image, .advisors .advisor .member-image, .advisors .advisor .advisor-image {
        width: 100%;
        float: none;
        margin-bottom: 20px;
    }

    .team .member .member-details, .team .member .advisor-details, .advisors .advisor .member-details, .advisors .advisor .advisor-details {
        width: 100%;
        float: none;
        padding: 0;
    }

    .team .member .advisor-details h3, .advisors .advisor .advisor-details h3 {
        line-height: 24px;
    }

    .advisors .advisor {
        margin-bottom: 50px;
    }

    .intro .landing-text p {
        margin-bottom: 40px;
    }

    .intro .landing-text .check input[type="text"] {
        width: 90%;
        min-width: 90%;
        margin-top: 20px;
    }

    .intro .landing-text .check input[type="submit"] {
        width: 100%;
        margin-top: 0px;
        float: none;
    }

    #newsletter .validate-email {
        min-width: 100%;
    }

    .pt40 {
        padding-top: 0;
    }

    .center-image {
        text-align: center;
    }

    .send-money h2 {
        line-height: 36px;
    }

    .send-money h3 {
        line-height: 30px;
    }

    .wrapper .intro.landing {
        background-size: initial;
    }

    .wrapper .intro.blue-map-with-background, .wrapper .intro.blue-map {
        background-position: top center;
        background-size: initial;
    }

    .intro .landing-text h1 .icon-with-title {
        display: block;
        top: 0px;
        width: 90px;
        margin: 0 auto 20px;
    }

    .intro .landing-text .marketing {
        padding: 0;
    }

    .intro .landing-text .marketing input[type="text"] {
        width: 90%;
    }

    .intro .landing-text .marketing input[type="submit"] {
        width: 90%;
        margin-right: 5%;
    }

    .image-right {
        float: none;
    }

    .intro.home {
        background: #009eda !important;
    }

    .youtube-video {
        padding-left: initial !important;
    }

    .wrapper .intro {
        background: #eeeeee;
    }

    .wrapper .intro.landing {
        background: #eeeeee;
        padding: 50px 0 50px;
    }

    .wrapper .intro .checking input[type="submit"] {
        display: block;
        margin: auto;
        margin-bottom: 20px !important;
    }

    .contact .sub-title {
        margin-bottom: 10px;
    }

    .selected-country img {
        margin: 0px;
    }

    .checkout-onepage-index .testimonials, .checkout-onepage-index .available-products {
        display: none;
    }

    .transfer-options h2, .why-choose h2, .testimonials h2, .operation-countries h2 {
        font-size: 25px;
        line-height: 1.4em;
    }

    .new-seo-page .cms-social-facebook {
        background-color: #2d78bd;
    }

    .center, .center-align {
        text-align: center;
    }
}
